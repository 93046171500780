@import "variables/colors";

* {
  -webkit-font-smoothing: antialiased;
}


html {
  font-family: $font-default;
  line-height: 1.43;
  font-size: 12px;
  @include breakpoint($breakpoint-lap-and-up) {
    font-size: 14px;
  }
}

body {
}

p {
  margin: 2em 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1rem;
  margin: 0;

  & + p {
    margin-top: 0;
  }
}