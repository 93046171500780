///////////////////////
// IMPORT FONTS HERE //
///////////////////////

$font-default: "tstar_pro",sans-serif;

@font-face {
    font-family: 'tstar_pro';
    src: url('../fonts/tstarpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/tstarpro-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}




@font-face {
    font-family: 'tstar_pro';
    src: url('../fonts/tstarpro-medium-webfont.woff2') format('woff2'),
         url('../fonts/tstarpro-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}