.svg--enjoy-dims {
	width: 36px;
	height: 96px;
}

.svg--logo-dims {
	width: 206px;
	height: 142px;
}

