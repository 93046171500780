@include add-grid(12);
@include add-gutter(0);

body {
  overflow-x: hidden;
  min-width: 320px;
}

img {
  display: block;
  width: 100%;
}

.l-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 3em;
}

.l-grid {
  @include clearfix;
  width: 1000px;

  @include breakpoint(max-width 900px) {
    display: flex;
    flex-direction: column;
  }
}

.l-grid__row {
  @include clearfix;
  clear: left;
}

.l-grid__first {
  @include grid-span(5, 2);
  padding-top: 14%;

  @include breakpoint(max-width 900px) {
    float: none;
    width: 100%;
    padding: 0;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 2em;
  }
}

.l-grid__second {
  @include grid-span(3.33, 7);

  @include breakpoint(max-width 900px) {
    display: none;
  }
}

.l-grid__third {
  @include grid-span(1.8, 11);

  @include breakpoint(1200px) {
    @include grid-span(2.8, 11);
  }

  @include breakpoint(1400px) {
    @include grid-span(3.7, 11);
  }
  padding-top: 16%;

  @include breakpoint(max-width 900px) {
    float: none;
    width: 100%;
    padding: 0;
    max-width: 300px;
    margin: 0 auto;
  }
}

.c-fancy-title {
  /* interieur & : */
  font-family: MuseoSlab-900;
  color: #B48B72;
  letter-spacing: 0.03em;
  line-height: 1.1;
  text-align: center;
  font-size: 20px;

  @include breakpoint(1200px) {
    font-size: 30px;
  }

  @include breakpoint(1400px) {
    font-size: 40px;
  }
}

.l-grid__fourth {
  @include grid-span(5, 1);

  @include breakpoint(max-width 900px) {
    text-align: center;
    float: none;
    width: 100%;
    margin: 0;
    padding: 0 3em;
  }
}

.l-grid__fifth {
  @include grid-span(3.33, 7-3.33);

  @include breakpoint(max-width 900px) {
    display: none;
  }

}

.l-grid__sixth {
  @include grid-span(5, 7);

  @include breakpoint(max-width 900px) {
    margin: 0 auto;
    float: none;
    width: 100%;
    max-width: 300px;
  }
}

.l-grid__seventh {
  @include grid-span(5, 7);
  margin-top: -14.33%;

  @include breakpoint(max-width 900px) {
    text-align: center;
    float: none;
    width: 100%;
    margin: 0;
    padding: 3em;
  }
}



// 283
// 441
// 1.56
//
.c-logo {
  order: -1;
  margin: 0 auto;
  max-width: 420px;

  @include breakpoint(901px) {
    margin-bottom: -100%;
    margin-top: -164px;
    width: 420px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

a {
  color: inherit;
}



.c-body {
  padding: 3em;
  /* Ballière interieur : */
  font-family: MuseoSlab-300;
  strong {
    font-family: MuseoSlab-700;
  }
  font-size: 15px;
  color: #404041;

  @include breakpoint(max-width 900px) {
    padding: 0;
  }
}

.c-announcement {
  /* WEBSITE ONLINE SOON: */
  font-family: Campton-SemiBold;
  font-size: 18px;
  color: #404041;
  letter-spacing: 6px;
  text-align: center;
  padding-top: 16.8%;

  @include breakpoint(max-width 900px) {
    margin: 0;
    padding: 0;
  }
}